import Cookies from 'js-cookie';

export default function useCookies() {
  const setAdvertisementCookies = (value: string) => {
    Cookies.set('advertisement', value);
  };

  const setOthersCookies = (value: string) => {
    Cookies.set('others', value);
  };

  const setAllAccepted = () => {
    Cookies.set('all_accepted', 'true');
  };

  const acceptAllCookies = () => {
    setAdvertisementCookies('true');
    setOthersCookies('true');
    setAllAccepted();
  };

  return {
    setAdvertisementCookies,
    setOthersCookies,
    setAllAccepted,
    acceptAllCookies,
  };
}
