import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

enum LabelPosition {
  'horizontal',
  'vertical',
}

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string; //TODO: The label property should be required, but is not currently implemented because it might cause another toggle to fail.
  labelPosition?: keyof typeof LabelPosition;
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ id, label, labelPosition = 'horizontal', className, ...rest }, ref) => {
    return (
      <div
        className={clsx(className, 'flex gap-2', [
          labelPosition === 'horizontal' && ['items-center'],
          labelPosition === 'vertical' && ['flex-col-reverse'],
        ])}
      >
        <label className='relative cursor-pointer' htmlFor={id}>
          <input
            type='checkbox'
            id={id}
            className='input-toggle sr-only'
            ref={ref}
            {...rest}
          />
          <div className='checkbox-bg h-4 w-7 rounded-full border border-gray-400'></div>
          <div className='dot absolute left-0.5 top-0.5 h-3 w-3 rounded-full border border-white bg-gray-400 transition'></div>
        </label>
        {label && <span className='text-xs text-gray-500'>{label}</span>}
      </div>
    );
  }
);

Toggle.displayName = 'Toggle';

export default Toggle;
