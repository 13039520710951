export const FUNCTIONAL_COOKIES = [
  {
    title: '__cf_bm',
    duration: '30 minutes',
    description:
      'This cookie, set by Cloudflare, is used to support Cloudflare Bot Management.',
  },
  {
    title: 'pll_language',
    duration: '1 year',
    description:
      '  The pll _language cookie is used by Polylang to remember the language selected by the user when returning to the website, and also to get the language information when not available in another way.',
  },
];

export const ANALYTICS_COOKIES = [
  {
    title: '_ga',
    duration: '2 years',
    description:
      'The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the sites analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.',
  },
  {
    title: '_ga_5K4DXHGH8W',
    duration: '2 years',
    description: 'This cookie is installed by Google Analytics.',
  },
  {
    title: '_gat_gtag_UA_200673265_1',
    duration: '1 minute',
    description: 'Set by Google to distinguish users.',
  },
  {
    title: '_gid',
    duration: '1 day',
    description:
      'Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website&aposs performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.',
  },
];

export const NECESSARY_COOKIES = [
  {
    title: 'cookielawinfo-checkbox-advertisement',
    duration: '1 year',
    description:
      'Set by the GDPR Cookie Consent plugin, this cookie is used to record the user consent for the cookies in the "Advertisement" category.',
  },
  {
    title: 'cookielawinfo-checkbox-analytics',
    duration: '11 months',
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Analytics".',
  },
  {
    title: 'cookielawinfo-checkbox-functional',
    duration: '11 months',
    description:
      'The cookie is set by GDPR cookie consent to record the user consent for the cookies in the category "Functional".',
  },
  {
    title: 'cookielawinfo-checkbox-necessary',
    duration: '11 months',
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Necessary".',
  },
  {
    title: 'cookielawinfo-checkbox-others',
    duration: '11 months',
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Other.',
  },
  {
    title: 'cookielawinfo-checkbox-performance',
    duration: '11 months',
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Performance".',
  },
  {
    title: 'viewed_cookie_policy',
    duration: '11 months',
    description:
      'The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data.',
  },
];
