import { ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { ChangeEvent, useRef } from 'react';

import Toggle from '../ui/Inputs/Toggle';

interface SettingsMenuProps {
  title: string;
  children?: JSX.Element;
  showToggle?: boolean;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  onClick: (isExpanded: boolean) => void;
  isExpanded: boolean;
}

const SettingsMenu = ({
  title,
  children,
  showToggle = false,
  onChange,
  checked,
  onClick,
  isExpanded,
}: SettingsMenuProps) => {
  const contentRef = useRef<HTMLElement>(null);

  return (
    <div className=''>
      <button
        className='mb-2 flex w-full flex-row items-center'
        onClick={() => onClick(!isExpanded)}
      >
        <div className='flex w-full items-center rounded-md bg-iron px-5 py-3 text-black'>
          <div className='flex w-full flex-row items-center justify-between'>
            <div className='flex flex-row items-center'>
              <ChevronRightIcon
                className={clsx(
                  isExpanded
                    ? 'rotate-90 transform duration-200'
                    : 'rotate-0 transform duration-200',
                  'mr-3 h-4 w-4'
                )}
                aria-hidden='true'
              />
              <h4 className='text-sm'>{title}</h4>
            </div>
            {!showToggle && (
              <p className='text-sm text-black'>Always enabled</p>
            )}
            {showToggle && (
              <div className='flex flex-row items-center'>
                <p className='mr-5 text-sm text-black'>Enabled</p>
                <Toggle onChange={onChange} checked={checked} />
              </div>
            )}
          </div>
        </div>
      </button>

      <section
        ref={contentRef}
        className='overflow-hidden transition-all duration-500'
        style={{
          height: `${isExpanded ? contentRef.current?.scrollHeight : 0}px`,
        }}
      >
        <div>{children}</div>
      </section>
    </div>
  );
};

export default SettingsMenu;
