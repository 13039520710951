import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export default function withTranslation<P>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>
) {
  return function WithTranslation(props: P) {
    const { t, lang } = useTranslation('common');
    return <WrappedComponent t={t} lang={lang} {...props} />;
  };
}
