import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { getApp, getApps, initializeApp } from 'firebase/app';

import { inDevEnvironment } from './isDevMode';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
};

let firebase;
if (!getApps().length) {
  firebase = initializeApp(firebaseConfig);
} else {
  firebase = getApp();
}

const shouldTrack =
  firebase.name && typeof window !== 'undefined' && !inDevEnvironment;

let analytics: Analytics;

if (shouldTrack) {
  analytics = getAnalytics(firebase);
}

const trackEvent = (event: string, params?: { [key: string]: unknown }) => {
  if (shouldTrack) {
    logEvent(analytics, event, params);
  }
};

const setCurrentScreenAnalytics = (page_path: string) => {
  if (shouldTrack) {
    trackEvent('page_view', { page_path });
  }
};

const trackReferrer = () => {
  if (shouldTrack && document.referrer) {
    trackEvent('referrer', {
      page_path: window.location.pathname,
      referrer: document.referrer,
    });
  }
};

export {
  analytics,
  firebase,
  setCurrentScreenAnalytics,
  trackEvent,
  trackReferrer,
};
