import clsx from 'clsx';
import * as React from 'react';
import { forwardRef } from 'react';
import { ImSpinner2 } from 'react-icons/im';

export enum ButtonVariant {
  'primary',
  'outline',
  'ghost',
  'ghost-gray',
  'light',
  'dark',
  'naked',
  'disabled',
  'black',
  'blue',
}

type ButtonProps = {
  isLoading?: boolean;
  variant?: keyof typeof ButtonVariant;
  type?: 'reset' | 'button' | 'submit';
} & React.ComponentPropsWithoutRef<'button'>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled: buttonDisabled,
      isLoading,
      variant = 'primary',
      type = 'button',
      ...rest
    },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled;

    return (
      <button
        {...rest}
        ref={ref}
        disabled={disabled}
        type={type}
        className={clsx(
          'rounded-lg px-6 py-1.5 text-sm font-normal',
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500/50 focus-visible:ring-offset-2',
          'transition-colors duration-75',
          [
            variant === 'primary' && [
              'bg-primary-400 text-white',
              'border border-primary-500',
              'hover:bg-primary-500 hover:text-white',
              'active:bg-primary-600',
              'disabled:border-inkanGray-400 disabled:bg-inkanGray-400 disabled:hover:bg-inkanGray-400',
            ],
            variant === 'outline' && [
              'text-primary-500',
              'border border-primary-500',
              'dark:hover:bg-gray-900 dark:active:bg-gray-800 dark:disabled:bg-gray-800',
              ' disabled:border-inkanTextLight disabled:text-inkanTextLight',
            ],
            variant === 'ghost' && [
              'text-primary-500',
              'shadow-none',
              'dark:hover:bg-gray-900 dark:active:bg-gray-800 dark:disabled:bg-gray-800',
              'disabled:bg-primary-100',
            ],
            variant === 'ghost-gray' && [
              'text-inkanText',
              'dark:hover:bg-gray-900 dark:active:bg-gray-800 dark:disabled:bg-gray-800',
              'disabled:bg-inkanTableRow',
            ],
            variant === 'light' && [
              'bg-white text-dark dark:bg-gray-900 ',
              'border border-gray-300',
              'hover:bg-gray-100 hover:text-dark',
              'active:bg-white disabled:bg-gray-200 dark:bg-gray-900/80',
            ],
            variant === 'dark' && [
              'bg-gray-900 text-white',
              'border border-gray-600',
              'hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-700',
            ],
            variant === 'naked' && [
              'bg-transparent',
              'shadow-none',
              'focus:outline-none focus-visible:rounded focus-visible:ring focus-visible:ring-blue-400',
              '!p-0',
              'm-0',
              'items-center',
            ],
            variant === 'black' && [
              'bg-transparent text-black',
              'border border-black',
              'hover:bg-gray-100 active:bg-gray-100',
            ],
            variant === 'blue' && [
              'bg-easternBlue text-white',
              'disabled:bg-inkanGray-500',
            ],
          ],
          'disabled:cursor-not-allowed',
          isLoading &&
            'relative !cursor-wait !text-transparent !transition-none hover:!text-transparent',
          variant === 'disabled' && [
            'border border-inkanTextLight',
            ' text-inkanTextLight',
            'cursor-not-allowed',
          ],
          className
        )}
      >
        {isLoading && (
          <div
            className={clsx(
              'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
              {
                'text-white': variant === 'dark' || variant === 'primary',
                'text-black': variant === 'light',
                'text-primary-500':
                  variant === 'outline' || variant === 'ghost',
              }
            )}
          >
            <ImSpinner2 className='animate-spin' />
          </div>
        )}
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
