import React from 'react';

interface CookieInformation {
  title: string;
  duration: string;
  description: string;
}

interface CookieTableProps {
  data: CookieInformation[];
}

const CookieTable = ({ data }: CookieTableProps) => {
  return (
    <div className='overflow-x-scroll'>
      <table className='mb-4 table-fixed text-sm text-black'>
        <thead className='bg-oxfordBlue text-white'>
          <tr className='p-4'>
            <th className='p-4'>Cookie</th>
            <th className='p-4'>Duration</th>
            <th className='p-4'>Description</th>
          </tr>
        </thead>
        <tbody className=' bg-athensGray p-2'>
          <>
            {data.map((cookie) => {
              return (
                <tr
                  className='align-text-top hover:bg-porcelain'
                  key={cookie.title}
                >
                  <td className='p-4'>{cookie.title}</td>
                  <td className='p-4'>{cookie.duration}</td>
                  <td className='p-4 leading-6'>{cookie.description}</td>
                </tr>
              );
            })}
          </>
        </tbody>
      </table>
    </div>
  );
};

export default CookieTable;
