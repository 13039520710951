// TODO: delete this file after refactoring. Use Dialog instead
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { Fragment, useRef } from 'react';

import Button, { ButtonVariant } from '@/components/buttons/Button';

export interface ModalProps {
  title?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  withFooter?: boolean;
  className?: string;
  withConfirm?: boolean;
  onConfirm?: () => void;
  variant?: 'primary' | 'dark';
  size?: 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  renderFooter?: () => React.ReactNode;
  renderClose?: () => React.ReactNode;
  confirmText?: string;
  closeText?: string;
  isSubmitting?: boolean;
  cancelButtonVariant?: keyof typeof ButtonVariant;
  onClose?: () => void;
  withBorder?: boolean;
}

const variantStyles = {
  primary: ['bg-white'],
  dark: ['bg-inkanDarkGray', 'border border-1 border-white', 'text-white'],
};

const footerStyles = {
  primary: ['bg-gray-50'],
  dark: ['bg-inkanDarkGray'],
};

const sizeMap = {
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
};

export default function Modal({
  title,
  isOpen,
  setIsOpen,
  children,
  withFooter = true,
  className,
  withConfirm,
  onConfirm,
  variant = 'primary',
  size = 'lg',
  renderFooter,
  renderClose,
  confirmText,
  closeText,
  isSubmitting,
  cancelButtonVariant = 'outline',
  onClose,
  withBorder = true,
}: ModalProps) {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('common');

  const closeModal = () => {
    if (onClose !== undefined) {
      onClose();
      return;
    }
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-[200] overflow-y-auto'
        initialFocus={cancelButtonRef}
        data-testid='ConfirmationModal'
        onClose={setIsOpen}
      >
        <div className='flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:h-screen sm:align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={clsx(
                variantStyles[variant],
                `inline-block  w-full transform overflow-hidden rounded-lg text-left align-bottom shadow-xl transition-all dark:bg-gray-800  sm:my-8 sm:w-full sm:align-middle`,
                sizeMap[size],
                withBorder && 'border border-black',
                className
              )}
            >
              {renderClose?.()}
              <div className='max-h-[80dvh] overflow-y-auto p-4'>
                <div className='sm:flex sm:items-start'>
                  <div className='w-full text-center sm:text-left'>
                    {title && (
                      <Dialog.Title
                        as='h3'
                        className='text-lg font-medium leading-6 text-gray-900'
                      >
                        {title}
                      </Dialog.Title>
                    )}

                    <div className={clsx(title && 'mt-2')}>{children}</div>
                  </div>
                </div>
              </div>

              {withFooter && (
                <div
                  className={clsx(
                    footerStyles[variant],
                    'flex-col px-4 py-3 text-center sm:flex sm:flex-row-reverse sm:px-6 md:text-right '
                  )}
                >
                  {withConfirm && onConfirm && (
                    <Button
                      className='mt-2 w-4/5 sm:mr-2 sm:mt-0 sm:w-auto'
                      type='button'
                      variant='primary'
                      onClick={onConfirm}
                      data-testid='ConfirmButton'
                      isLoading={isSubmitting}
                    >
                      {confirmText || t('confirm')}
                    </Button>
                  )}
                  <Button
                    className='mt-2 w-4/5 sm:mr-2 sm:mt-0 sm:w-auto'
                    type='button'
                    variant={cancelButtonVariant}
                    onClick={closeModal}
                    ref={cancelButtonRef}
                    data-testid='CloseButton'
                  >
                    {closeText || t('close')}
                  </Button>
                </div>
              )}
              {!withFooter && renderFooter?.()}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
