import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';

import {
  ANALYTICS_COOKIES,
  FUNCTIONAL_COOKIES,
  NECESSARY_COOKIES,
} from '@/constants/CookiesInformation';

import CookieTable from './CookieTable';
import SettingsMenu from './SettingsMenu';

import { CustomCookies } from '@/types';

interface CookieSettingsProps {
  customCookies: CustomCookies;
  setCustomCookies: (cookies: CustomCookies) => void;
}

const MENU_INITIAL_STATE = {
  analytics: false,
  functional: false,
  necessary: false,
  performance: false,
  advertisement: false,
  others: false,
};

const CookieSettings = ({
  setCustomCookies,
  customCookies,
}: CookieSettingsProps) => {
  const { t } = useTranslation('cookies');
  const [readMore, setReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(MENU_INITIAL_STATE);

  const toggleMenu = (menu: string, value: boolean) => {
    setIsExpanded({
      ...MENU_INITIAL_STATE,
      [menu]: value,
    });
  };

  return (
    <div className='mx-5 mt-5 text-left sm:mx-12 sm:mt-11'>
      <p className='mb-4 text-xl text-black'>Privacy Overview</p>
      <div className='mb-2 text-sm'>
        <p className='inline-block'>
          This website uses cookies to improve your experience while you
          navigate through the website. Out of these, the cookies that are
          categorized as necessary are stored on your browser as they are
          essential for the working of basic functionalities of the
        </p>
        {!readMore && <p className='contents'> ...</p>}{' '}
        {readMore && (
          <p className='contents'>
            website. We also use third-party cookies that help us analyze and
            understand how you use this website. These cookies will be stored in
            your browser only with your consent. You also have the option to
            opt-out of these cookies. But opting out of some of these cookies
            may affect your browsing experience.
          </p>
        )}
      </div>
      <button
        className='mb-4 cursor-pointer text-sm underline'
        onClick={() => setReadMore((prevReadMore) => !prevReadMore)}
      >
        Show {readMore ? 'Less' : 'More'}
      </button>
      <SettingsMenu
        title='Analytics'
        isExpanded={isExpanded.analytics}
        onClick={(toggle) => toggleMenu('analytics', toggle)}
      >
        <>
          <p className='my-4 text-sm text-black'>{t('analytics')}</p>
          <CookieTable data={ANALYTICS_COOKIES} />
        </>
      </SettingsMenu>

      <SettingsMenu
        title='Functional'
        isExpanded={isExpanded.functional}
        onClick={(toggle) => toggleMenu('functional', toggle)}
      >
        <>
          <p className='my-4 text-sm text-black'>{t('functional')}</p>
          <CookieTable data={FUNCTIONAL_COOKIES} />
        </>
      </SettingsMenu>

      <SettingsMenu
        title='Necessary'
        isExpanded={isExpanded.necessary}
        onClick={(toggle) => toggleMenu('necessary', toggle)}
      >
        <>
          <p className='my-4 text-sm text-black'>{t('necessary')}</p>
          <CookieTable data={NECESSARY_COOKIES} />
        </>
      </SettingsMenu>

      <SettingsMenu
        title='Performance'
        isExpanded={isExpanded.performance}
        onClick={(toggle) => toggleMenu('performance', toggle)}
      >
        <p className='my-4 text-sm text-black'>{t('performance')}</p>
      </SettingsMenu>

      <SettingsMenu
        title='Advertisement'
        showToggle
        isExpanded={isExpanded.advertisement}
        checked={customCookies.advertisement === 'true'}
        onChange={(ev) =>
          setCustomCookies({
            ...customCookies,
            advertisement: String(ev.target.checked),
          })
        }
        onClick={(toggle) => toggleMenu('advertisement', toggle)}
      >
        <p className='my-4 text-sm text-black'>{t('advertisement')}</p>
      </SettingsMenu>

      <SettingsMenu
        title='Others'
        showToggle
        isExpanded={isExpanded.others}
        checked={customCookies.others === 'true'}
        onChange={(ev) =>
          setCustomCookies({
            ...customCookies,
            others: String(ev.target.checked),
          })
        }
        onClick={(toggle) => toggleMenu('others', toggle)}
      >
        <p className='my-4 text-sm text-black'>{t('others')}</p>
      </SettingsMenu>
    </div>
  );
};

export default CookieSettings;
