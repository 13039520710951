
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import Cookies from 'js-cookie';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import NextNprogress from 'nextjs-progressbar';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

import '@/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-datepicker/dist/react-datepicker.css';

import CookieBanner from '@/components/CookieBanner/CookieBanner';

import withTranslation from '@/hoc/withTranslation';
import { setCurrentScreenAnalytics, trackEvent } from '@/utils/firebase';

const queryClient = new QueryClient({});

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  const WithTranslation = withTranslation(Component);
  const router = useRouter();
  const [allCookiesAccepted, setAllCookiesAccepted] = useState(false);

  useEffect(() => {
    setAllCookiesAccepted(Cookies.get('all_accepted') === 'true');
  }, []);

  useEffect(() => {
    const logEvent = (url: string) => {
      setCurrentScreenAnalytics(url);
      trackEvent('screen_view');
    };

    router.events.on('routeChangeComplete', logEvent);
    logEvent(window.location.pathname);

    return () => {
      router.events.off('routeChangeComplete', logEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={session}>
        <ThemeProvider
          attribute='class'
          disableTransitionOnChange
          defaultTheme='light'
        >
          <NextNprogress color='#ff3205' />
          <WithTranslation {...pageProps} />
          <ToastContainer className='Toastr' />
          {!allCookiesAccepted && (
            <CookieBanner
              onAcceptedAllCookies={() => setAllCookiesAccepted(true)}
            />
          )}
        </ThemeProvider>
      </SessionProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  